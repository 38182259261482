import {
    type ComponentType,
    Fragment,
    type FunctionComponent,
    type ReactElement,
    type ReactNode,
    Suspense,
    createElement,
    isValidElement,
    memo,
    useId
} from "react"

import { isEqual } from "$/utils/gates"

import { ContentLoader } from "@/3514/components"

type TProps<CProps> = {
    component: ReactElement | ComponentType
    componentProps?: CProps
    isLoading?: boolean
    isReady?: boolean
    withoutSuspense?: boolean
    loadingFallback?: ReactElement
    noDataFallback?: ReactElement
}

/**
 * Component-helper for visually dynamic component loading. Preferred to use with React.lazy()
 * Renders <ContentLoader /> component for loading state be default
 * @param isLoading boolean parameter indicating fetch status process
 * @param component ReactElement to be displayed once data is loaded
 * @param componentProps boolean parameter indicating fetch status process
 * @param isReady optional boolean parameter indicating fetch status completion
 * @param withoutSuspense boolean parameter for disabling internal wrapping in <React.Suspense />
 * @param loadingFallback ReactElement to display instead of component while loading
 * @param noDataFallback ReactElement for the empty data to be displayed instead of component
 * @return {ReactElement} based on internal condition
 */
const Component: <TComponentProps>(props: TProps<TComponentProps>) => ReactElement = memo(
    <TComponentProps,>({
        component: C,
        componentProps,
        isLoading = false,
        isReady = true,
        withoutSuspense = false,
        loadingFallback = undefined,
        noDataFallback = undefined
    }: TProps<TComponentProps>): ReactElement => {
        const loaderUniqueKey: string = useId()
        const LoadingFallback: ReactElement = loadingFallback ?? <ContentLoader uniqueKey={loaderUniqueKey} />
        const NoDataFallback: ReactElement = noDataFallback ?? <Fragment />

        const Wrapper: FunctionComponent<{ children: ReactNode }> = ({
            children
        }: {
            children: ReactNode
        }): ReactElement =>
            withoutSuspense ? (
                <Fragment>{children}</Fragment>
            ) : (
                <Suspense fallback={LoadingFallback}>{children}</Suspense>
            )

        const RenderedComponent: ReactElement = isValidElement(C) ? C : createElement(C, componentProps)

        return <Wrapper>{isLoading ? LoadingFallback : isReady ? RenderedComponent : NoDataFallback}</Wrapper>
    },
    <TComponentProps,>(prevProps: TProps<TComponentProps>, nextProps: TProps<TComponentProps>): boolean =>
        isEqual(prevProps, nextProps)
)

export { Component as LoadableComponent, type TProps as TLoadableComponentProps }
