import { type LazyExoticComponent, type NamedExoticComponent, lazy } from "react"

import type { TParticipantChapterContentContainerProps as TContainerProps } from "./participant-chapter-content.container"

const Container: LazyExoticComponent<NamedExoticComponent<TContainerProps>> = lazy(
    (): Promise<{ default: NamedExoticComponent<TContainerProps> }> =>
        import("./participant-chapter-content.container").then(
            ({ ParticipantChapterContentContainer: C }): { default: NamedExoticComponent<TContainerProps> } => ({
                default: C
            })
        )
)

export { Container as ParticipantChapterContentContainer }
