import { ETypographyColor, ETypographySize, ETypographyTag, type TTypographyProps } from "../typography"

import { EButtonSize, EButtonVariant } from "./button.types"

const sizeMap: { [S in EButtonSize]: string } = {
    [EButtonSize.Medium]: "px-[25px] py-[8px] rounded-[30px]",
    [EButtonSize.Smaller]: "px-[25px] py-[8px] rounded-[30px]"
}

const colorMap: { [V in EButtonVariant]: (isDisabled: boolean) => string } = {
    [EButtonVariant.Solid]: (isDisabled: boolean): string =>
        [
            "flex items-center justify-center transition-colors duration-200",
            isDisabled ? "bg-gray-300" : "bg-blue-200 hover:bg-accent focus:bg-accent"
        ].join(" "),
    [EButtonVariant.Outlined]: (isDisabled: boolean): string =>
        [
            "flex items-center justify-center border-[1px] bg-white transition-colors duration-200",
            isDisabled ? "border-gray-300" : "border-blue-200 hover:border-accent focus:border-accent"
        ].join(" ")
}

function getTypographyProps(
    btnSize: EButtonSize,
    btnVariant: EButtonVariant,
    isBtnDisabled: boolean
): Omit<TTypographyProps, "text"> {
    return {
        tag: ETypographyTag.Span,
        size: btnSize === EButtonSize.Medium ? ETypographySize.Medium : ETypographySize.Small,
        color:
            isBtnDisabled && btnVariant === EButtonVariant.Solid
                ? ETypographyColor.LightGray
                : isBtnDisabled && btnVariant === EButtonVariant.Outlined
                  ? ETypographyColor.DarkGray
                  : !isBtnDisabled && btnVariant === EButtonVariant.Solid
                    ? ETypographyColor.White
                    : !isBtnDisabled && btnVariant === EButtonVariant.Outlined && ETypographyColor.Dark
    }
}

const config = { colorMap, sizeMap, getTypographyProps }

export { config as buttonConfig }
