import type { AxiosResponse } from "axios"

import { http } from "$/http"

import Urls from "@/api/urls"
import type { IResourceDto } from "@/pages/participant-dashboard/api/dto"

async function fetcher(): Promise<IResourceDto[]> {
    const { data }: AxiosResponse<IResourceDto[]> = await http.get(Urls.getResources())

    return data
}

export { fetcher as resourcesQueryFetcher }
