import {
    type Dispatch,
    type FC,
    type ReactElement,
    type SetStateAction,
    startTransition,
    useCallback,
    useDeferredValue,
    useEffect,
    useState
} from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { type Location, useLocation } from "react-router-dom"

import useDebounce from "$/hooks/use-debounce"
import { DEFAULT_PAGINATION_PAGE } from "$/hooks/use-pagination"
import useOnRouteChange from "$/hooks/use-route-change"
import { getErrorMessages } from "$/utils/get-error-messages"

import useGetCoachSessions from "@/api/use-coach-sessions"
import useParticipants from "@/api/use-participants"
import {
    type IUsePaginatedSessions,
    usePaginatedSessions
} from "@/components/group-info/hooks/use-paginated-sessions-props"
import { ECoachDashboardTab, ServerStateKeys, SortKeysSession } from "@/constants"
import AttendanceModal from "@/elements/attendance-modal/AttendanceModal"
import {
    type IUseTranslation,
    type TGetValueFromTranslatedObjectFunction,
    useGetValueFromTranslatedObject,
    useTranslation
} from "@/hooks"
import { CoachLayout } from "@/layouts"
import { Participant } from "@/models/participants"
import { Session } from "@/models/session"
import CareTeamLink from "@/shared/CareTeamLink"
import DateWidget from "@/shared/DateWidget"
import CoachSetupScheduleModal from "@/widgets/coach-setup-schedule-modal/ui/CoachSetupScheduleModal"

import { type TCoachDashboardApiUtils, coachDashboardApiUtils } from "./api/utils"
import { CoachDashboardHeader as Header } from "./ui/Header"
import { CoachTable } from "./ui/tables/CoachTable"
import { coachGroupsTableConfig, coachParticipantsTableConfig, coachSessionsTableConfig } from "./ui/tables/config"
import { ECoachTableHeaderName, ECoachTableType, TDataStateForTable } from "./ui/types"
import { getDataStateForTable, getTableStatusText } from "./ui/utils"

import "./styles.scss"

const { fetchCoachSessionsWithoutAttendanceMarks }: TCoachDashboardApiUtils = coachDashboardApiUtils

const CoachDashboard: FC = (): ReactElement => {
    const location: Location = useLocation()

    const [searchValue, setSearchValue]: [string, Dispatch<SetStateAction<string>>] = useState<string>("")
    const [selectedPeriod, setSelectedPeriod]: [string, Dispatch<SetStateAction<string>>] =
        useState<string>("upcoming_sessions") // TODO: add enum here
    const [sessionTimeOrderSort, setSessionTimeOrderSort]: [
        SortKeysSession,
        Dispatch<SetStateAction<SortKeysSession>>
    ] = useState<SortKeysSession>(SortKeysSession.Desc)
    const [selectedTab, setSelectedTab]: [ECoachDashboardTab, Dispatch<SetStateAction<ECoachDashboardTab>>] =
        useState<ECoachDashboardTab>(location.pathname.slice(1) as ECoachDashboardTab)
    const [showSetupModal, setShowSetupModal]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)
    const debouncedValue: string = useDebounce(searchValue, 300)

    const { t }: IUseTranslation = useTranslation()

    const client: QueryClient = useQueryClient()

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    client.prefetchQuery([ServerStateKeys.CoachSessionWithoutAttendanceMarks], fetchCoachSessionsWithoutAttendanceMarks)

    const refetchSessionWithoutAttendanceMarksData: () => Promise<void> = useCallback(
        (): Promise<void> => client.refetchQueries([ServerStateKeys.CoachSessionWithoutAttendanceMarks]),
        [client]
    )

    const [currentParticipantsPaginationPage, setCurrentParticipantsPaginationPage]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState<number>(DEFAULT_PAGINATION_PAGE)

    const {
        data: participantsData,
        isLoading: isParticipantsDataLoading,
        isError: isErrorParticipants,
        error: errorParticipants
    } = useParticipants({
        search: debouncedValue,
        enabled: location.pathname.includes(ECoachDashboardTab.PARTICIPANTS),
        page: currentParticipantsPaginationPage
    })

    const isGroupsOrSessionsTab: boolean =
        location.pathname.includes(ECoachDashboardTab.SESSIONS) || location.pathname.includes(ECoachDashboardTab.GROUP)

    const [currentSessionsPaginationPage, setCurrentSessionsPaginationPage]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState<number>(DEFAULT_PAGINATION_PAGE)

    const {
        data: sessionData,
        isFetching: isFetchingSessions,
        isError,
        error
    } = useGetCoachSessions({
        search: debouncedValue,
        order: sessionTimeOrderSort,
        period: selectedPeriod,
        enabled: isGroupsOrSessionsTab,
        page: currentSessionsPaginationPage
    })

    useOnRouteChange(
        useCallback(
            (): void =>
                startTransition(
                    (): void => (
                        setSelectedTab(window.location.pathname.slice(1) as ECoachDashboardTab),
                        setCurrentSessionsPaginationPage(DEFAULT_PAGINATION_PAGE),
                        setCurrentSessionsPaginationPage(DEFAULT_PAGINATION_PAGE)
                    )
                ),
            []
        )
    )

    const changeCurrentParticipantsPaginationPage: (page: number) => void = useCallback(
        (page: number): void => startTransition((): void => setCurrentParticipantsPaginationPage(page)),
        []
    )

    const changeCurrentSessionsPaginationPage: (page: number) => void = useCallback(
        (page: number): void => startTransition((): void => setCurrentSessionsPaginationPage(page)),
        []
    )

    const sortBySessionTime: (order: boolean) => void = useCallback(
        (order: boolean): void => setSessionTimeOrderSort(order ? SortKeysSession.Desc : SortKeysSession.Asc),
        []
    )

    const onShowSetupModal: (val: boolean) => void = useCallback((value: boolean): void => setShowSetupModal(value), [])

    const onSelectSessionPeriod: (val: string) => void = useCallback(
        (value: string): void => (setSelectedPeriod(value), setCurrentSessionsPaginationPage(DEFAULT_PAGINATION_PAGE)),
        []
    )

    const getShouldSelectBeVisible: (location: Location) => boolean = useCallback(
        (location: Location): boolean =>
            location.pathname.includes(ECoachDashboardTab.GROUP) ||
            location.pathname.includes(ECoachDashboardTab.SESSIONS),
        []
    )

    const deferredSessionsData: Session[] = useDeferredValue(sessionData?.sessions)

    const [sortedParticipantsData, setSortedParticipantsData]: [
        Participant[],
        Dispatch<SetStateAction<Participant[]>>
    ] = useState<Participant[]>(participantsData?.participants ?? [])

    const { noSessionsInCohort, noSearchResults, noSessionsData, noParticipantsData }: TDataStateForTable =
        getDataStateForTable({
            sessionsData: deferredSessionsData,
            participantsData: sortedParticipantsData,
            isSessionsDataLoading: isFetchingSessions,
            searchValue
        })

    useEffect(
        (): void => (setSortedParticipantsData(participantsData?.participants), void 0),
        [participantsData?.participants]
    )

    const handleParticipantDataSort: (order: boolean, name?: ECoachTableHeaderName) => void = useCallback(
        (order: boolean, name: ECoachTableHeaderName): void =>
            startTransition(
                (): void => (
                    name === ECoachTableHeaderName.Participants_Name &&
                        setSortedParticipantsData((prev: Participant[]): Participant[] =>
                            [...prev].sort((a: Participant, b: Participant): number =>
                                order ? a.firstName.localeCompare(b.firstName) : b.firstName.localeCompare(a.firstName)
                            )
                        ),
                    name === ECoachTableHeaderName.Participants_CurrentModule &&
                        setSortedParticipantsData((prev: Participant[]): Participant[] =>
                            [...prev].sort((a: Participant, b: Participant): number =>
                                order
                                    ? getValueFromTranslatedObject(a.currentModule?.versionName)?.localeCompare(
                                          getValueFromTranslatedObject(b.currentModule?.versionName)
                                      )
                                    : getValueFromTranslatedObject(b.currentModule?.versionName)?.localeCompare(
                                          getValueFromTranslatedObject(a.currentModule?.versionName)
                                      )
                            )
                        )
                )
            ),
        []
    )

    const {
        sessionId,
        isAttendanceModalShown,
        handleCloseAttendanceModal,
        handleOpenAttendanceModal,
        handleSubmitAttendanceModal
    }: IUsePaginatedSessions = usePaginatedSessions()

    return (
        <CoachLayout leftSidebar={null} headerTitle={<DateWidget className="coach-date-title" />}>
            <Header
                onSearch={setSearchValue}
                onClickSetupCalendar={onShowSetupModal}
                selectedPeriod={selectedPeriod}
                onSelectSessionPeriod={onSelectSessionPeriod}
                handleOpenAttendanceModal={handleOpenAttendanceModal}
                getShouldSelectBeVisible={getShouldSelectBeVisible}
            />

            {selectedTab === ECoachDashboardTab.SESSIONS && (
                <CoachTable
                    {...coachSessionsTableConfig}
                    dataSource={deferredSessionsData}
                    currentPaginationPage={currentSessionsPaginationPage}
                    handlePageChange={changeCurrentSessionsPaginationPage}
                    totalPaginationCount={sessionData?.totalSessionsCount}
                    isLoading={isFetchingSessions}
                    onSort={sortBySessionTime}
                    testId="sessions-table"
                    error={isError ? getErrorMessages(error).join("") : null}
                    errorStatusText={getTableStatusText({
                        noSessionsData,
                        noSessionsInCohort,
                        noSearchResults,
                        tableType: ECoachTableType.Sessions
                    })}
                />
            )}
            {selectedTab === ECoachDashboardTab.PARTICIPANTS && (
                <CoachTable
                    {...coachParticipantsTableConfig}
                    dataSource={sortedParticipantsData}
                    currentPaginationPage={currentParticipantsPaginationPage}
                    handlePageChange={changeCurrentParticipantsPaginationPage}
                    totalPaginationCount={participantsData?.totalParticipantsCount}
                    isLoading={isParticipantsDataLoading}
                    onSort={handleParticipantDataSort}
                    testId="participants-table"
                    error={isErrorParticipants ? getErrorMessages(errorParticipants)?.join("") : null}
                    errorStatusText={getTableStatusText({
                        noSearchResults,
                        noParticipantsData,
                        tableType: ECoachTableType.Participants
                    })}
                />
            )}
            {selectedTab === ECoachDashboardTab.GROUP && (
                <CoachTable
                    {...coachGroupsTableConfig}
                    dataSource={deferredSessionsData?.filter((s: Session): boolean => s?.cohortModality === "group")}
                    currentPaginationPage={currentSessionsPaginationPage}
                    handlePageChange={changeCurrentSessionsPaginationPage}
                    totalPaginationCount={sessionData?.totalSessionsCount}
                    isLoading={isFetchingSessions}
                    onSort={sortBySessionTime}
                    testId="groups-table"
                    error={isError ? getErrorMessages(error).join("") : null}
                    errorStatusText={getTableStatusText({
                        noSessionsData,
                        noSessionsInCohort,
                        noSearchResults,
                        tableType: ECoachTableType.Groups
                    })}
                />
            )}

            <CareTeamLink className="care-team-link" text={t("coachSide.careLink.questionText")} />
            {showSetupModal && (
                <CoachSetupScheduleModal show={showSetupModal} onClose={() => onShowSetupModal(false)} />
            )}

            {isAttendanceModalShown && (
                <AttendanceModal
                    show={isAttendanceModalShown}
                    sessionId={sessionId}
                    onSubmit={() => handleSubmitAttendanceModal(refetchSessionWithoutAttendanceMarksData)}
                    onClose={handleCloseAttendanceModal}
                />
            )}
        </CoachLayout>
    )
}

export { CoachDashboard }
