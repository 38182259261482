enum EButtonVariant {
    Solid = "solid",
    Outlined = "outlined"
}

enum EButtonSize {
    Medium = "medium",
    Smaller = "smaller"
}

export { EButtonVariant, EButtonSize }
