import type {
    TModulePhaseProgressStatusDto,
    TModulePhaseTrackStatusDto,
    TSessionAttendanceStatusDto
} from "@/3514/types"
import { EModulePhaseProgressStatus, EModulePhaseTrackStatus, ESessionAttendanceStatus } from "@/3514/types"

function getSessionAttendanceStatusEnumFromType(status: TSessionAttendanceStatusDto): ESessionAttendanceStatus {
    switch (status) {
        case "accepted":
            return ESessionAttendanceStatus.Accepted
        case "declined":
            return ESessionAttendanceStatus.Declined
        case "tentative":
            return ESessionAttendanceStatus.Tentative
        case "pending":
            return ESessionAttendanceStatus.Pending
        case "present":
            return ESessionAttendanceStatus.Present
        case "not_present":
            return ESessionAttendanceStatus.NotPresent
        case null:
        default:
            return null
    }
}

function getModulePhaseTrackStatusEnumFromType(status: TModulePhaseTrackStatusDto): EModulePhaseTrackStatus {
    switch (status) {
        case "on_track":
            return EModulePhaseTrackStatus.OnTime
        case "late":
            return EModulePhaseTrackStatus.Late
        case "not_started":
        default:
            return EModulePhaseTrackStatus.NotStarted
    }
}

function getModulePhaseProgressEnumFromType(progress: TModulePhaseProgressStatusDto): EModulePhaseProgressStatus {
    switch (progress) {
        case 1:
            return EModulePhaseProgressStatus.FirstChapterCompleted
        case 2:
            return EModulePhaseProgressStatus.SecondChapterCompleted
        case 3:
            return EModulePhaseProgressStatus.ThirdChapterCompleted
        case -1:
        case 0:
        default:
            return EModulePhaseProgressStatus.NotAvailable
    }
}

export {
    getSessionAttendanceStatusEnumFromType,
    getModulePhaseTrackStatusEnumFromType,
    getModulePhaseProgressEnumFromType
}
