import { type FC, type LazyExoticComponent, lazy } from "react"

export type { TParticipantAnalyticsModalContainerProps } from "./participant-analytics"
export type { TParticipantSessionAttendanceModalContainerProps } from "./participant-session-attendance"

const AnalyticsModal: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./participant-analytics").then(
            ({ ParticipantAnalyticsModalContainer: AnalyticsModal }): { default: FC } => ({
                default: AnalyticsModal
            })
        )
)
const SessionAttendanceModal: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./participant-session-attendance").then(
            ({ ParticipantSessionAttendanceModalContainer: SessionAttendanceModal }): { default: FC } => ({
                default: SessionAttendanceModal
            })
        )
)

export { SessionAttendanceModal, AnalyticsModal }
