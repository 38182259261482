enum EModulePhaseOrChapterUiStatus {
    Current = "current", // phase or chapter
    Locked = "locked", // phase or chapter
    NotStarted = "notStarted", // chapter
    Completed = "completed", // phase or chapter
    CompletedInCurrentPhase = "completedInCurrentPhase", // chapter
    CompletedInPreviousPhase = "completedInPreviousPhase" // chapter
}

export { EModulePhaseOrChapterUiStatus }
