import type { FC, ReactElement } from "react"

import classNames from "classnames"

import { ESessionAttendanceStatus } from "@/pages/participant-dashboard/models"

import { rsvpMarkConfig as config } from "./rsvp-mark.config"

type TProps = { status: ESessionAttendanceStatus }

const Component: FC<TProps> = ({ status }: TProps): ReactElement => (
    <i
        className={classNames(
            "w-[15px] h-[15px] rounded-full outline-[1px] flex items-center justify-center not-italic",
            status === ESessionAttendanceStatus.Present || status === ESessionAttendanceStatus.NotPresent
                ? "bg-green-200 text-black outline outline-green-200"
                : "bg-transparent outline-dashed text-gray-200 outline-gray-200",
            status === ESessionAttendanceStatus.Tentative || status === ESessionAttendanceStatus.Pending
                ? "font-[700]"
                : "font-[800]",
            {
                "text-[13px]":
                    status === ESessionAttendanceStatus.NotPresent || status === ESessionAttendanceStatus.Declined,
                "text-[10px]":
                    status === ESessionAttendanceStatus.Tentative || status === ESessionAttendanceStatus.Pending,
                "text-[7px]":
                    status === ESessionAttendanceStatus.Accepted || status === ESessionAttendanceStatus.Present
            }
        )}
    >
        {status ? config.symbolsMap[status] : null}
    </i>
)

Component.displayName = "RsvpMark"

export { Component as RsvpMark, type TProps as TRsvpMarkProps }
