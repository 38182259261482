import { isEmpty } from "$/utils/gates"

import type { IResourceDto, TResourceType } from "@/pages/participant-dashboard/api/dto"
import { EResourceType, type IResourceModel } from "@/pages/participant-dashboard/models"

function _getResourceEnumFromType(type: TResourceType): EResourceType {
    switch (type) {
        case "video":
            return EResourceType.Video
        case "audio":
            return EResourceType.Audio
        case "article":
        default:
            return EResourceType.Article
    }
}

function adapter(dtos: IResourceDto[]): IResourceModel[] {
    return isEmpty(dtos)
        ? []
        : dtos.map(
              ({ type, ...rest }: IResourceDto): IResourceModel => ({ type: _getResourceEnumFromType(type), ...rest })
          )
}

export { adapter as resourcesQueryAdapter }
