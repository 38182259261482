import type { FC, ReactElement } from "react"

import ContentLoader, { type IContentLoaderProps } from "react-content-loader"

import { contentLoaderConfig } from "./content-loader.config"

type TProps = IContentLoaderProps & { withoutBorders?: boolean }

/**
 * ContentLoader component. Uses rectangular gradients by default
 * @param speed number
 * @param height string
 * @param width string
 * @param backgroundColor string
 * @param foregroundColor string
 * @param gradientDirection string
 * @param rest rest of params
 * @return ReactElement
 */
const Component: FC<TProps> = ({
    speed = 3,
    height = "100%",
    width = "100%",
    backgroundColor = contentLoaderConfig.BACKGROUND_COLOR,
    foregroundColor = contentLoaderConfig.FOREGROUND_COLOR,
    gradientDirection = "top-bottom",
    ...rest
}: TProps): ReactElement => (
    <ContentLoader
        speed={speed}
        height={height}
        width={width}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        gradientDirection={gradientDirection}
        {...rest}
    >
        <rect x="7" y="14" rx="0" ry="0" width="100%" height="100%" />
        <rect x="122" y="71" rx="0" ry="0" width="0" height="1" />
    </ContentLoader>
)

Component.displayName = "ContentLoader"

export { Component as ContentLoader, type TProps as TContentLoaderProps }
