import { isEmpty } from "$/utils/gates"

import {
    type IModuleExcerptDto,
    type IModuleExcerptModel,
    type IModuleExcerptPhaseDto,
    type IModuleExcerptPhaseModel
} from "@/3514/entities/participant-header/types"
import {
    getModulePhaseProgressEnumFromType,
    getModulePhaseTrackStatusEnumFromType,
    getSessionAttendanceStatusEnumFromType
} from "@/3514/utils"

function adapter(dtos: IModuleExcerptDto[]): IModuleExcerptModel[] {
    return isEmpty(dtos)
        ? []
        : dtos.reduce((acc: IModuleExcerptModel[], dto: IModuleExcerptDto): IModuleExcerptModel[] => {
              const {
                  title,
                  rank,
                  current_module: isCurrentModule,
                  associated_session,
                  phases
              }: IModuleExcerptDto = dto

              const moduleExcerpt: IModuleExcerptModel = {
                  title,
                  rank,
                  isCurrentModule,
                  phases: isEmpty(phases)
                      ? []
                      : phases.map(
                            ({
                                order,
                                phase_progress_bar,
                                track_status
                            }: IModuleExcerptPhaseDto): IModuleExcerptPhaseModel => ({
                                order,
                                progressStatus: getModulePhaseProgressEnumFromType(phase_progress_bar),
                                trackStatus: getModulePhaseTrackStatusEnumFromType(track_status)
                            })
                        ),
                  associatedSession: isEmpty(associated_session)
                      ? null
                      : {
                            id: associated_session.id,
                            coach: associated_session.coach,
                            status: getSessionAttendanceStatusEnumFromType(associated_session.status)
                        }
              }

              acc.push(moduleExcerpt)

              return acc
          }, [])
}

export { adapter as modulesExcerptsQueryAdapter }
