import type { FC, ReactElement } from "react"

import Sidebar from "@/components/dashboard/LeftSidebar"
import { ParticipantLayout as Layout } from "@/layouts"
import { ESidebarTag } from "@/shared/sidebar/Sidebar"

import { ParticipantChapterContainer as Container } from "."

/**
 * Participant' Chapter page. Design: {@link https://www.figma.com/design/J6qF3Pa6zeK3udtVWLskjd/Participant?node-id=13200-158580&m=dev}
 * @return ReactElement
 */
const Page: FC = (): ReactElement => {
    return (
        <Layout tag="main" leftSidebar={<Sidebar tag={ESidebarTag.Aside} />}>
            <Container />
        </Layout>
    )
}

Page.displayName = "ParticipantChapterPage"

export { Page as ParticipantChapterPage }
