import {
    type FC,
    type MutableRefObject,
    type ReactElement,
    type ReactNode,
    useCallback,
    useEffect,
    useRef
} from "react"

import classNames from "classnames"

import { useKeyPress } from "$/hooks/use-key-press"
import { useOnClickOutside } from "$/hooks/use-outside-click"

import { ETypographyFontWeight, ETypographySize, ETypographyTag, Typography } from "@/3514/components"
import { MAIN_PORTAL_ID } from "@/constants"
import { type IUseTranslation, TUseModal, useModal, useTranslation } from "@/hooks"
import Portal from "@/shared/portal/Portal"
import { type TEmptyCallback } from "@/shared/types/functions"
import { EModalId } from "@/store/slices"
import { CrossIconSvg } from "@/svgs/CrossIconSvg"

type TProps = {
    isShown: boolean
    modalId: EModalId
    onHide?(): void
    title?: string
    modalBodyClassName?: string
    withOutsideClick?: boolean
    withDisabledBodyScroll?: boolean
    headerElement?: ReactNode | null
    shouldCloseOnEscapeClick?: boolean
    withPortal?: boolean
    portalId?: string
    withCloseIcon?: boolean
    children?: ReactNode
}

const classes = {
    modalWrapper:
        "h-full w-screen bg-black/25  backdrop-blur-sm fixed top-0 left-0 flex items-center justify-center transition-opacity duration-500 transform scale-0",
    modalWrapperShown: "opacity-100 scale-100 z-[100]",
    modalBody: "relative bg-white transform scale-50 transition-all duration-400 h-fit",
    modalBodyShown: "!scale-100 z-[120]",
    closeBtn: "absolute top-[17px] right-[17px] border-none bg-transparent"
}

const Component: FC<TProps> = ({
    isShown,
    modalId,
    onHide,
    title = String(),
    modalBodyClassName = String(),
    withOutsideClick = true,
    withDisabledBodyScroll = false,
    headerElement = null,
    shouldCloseOnEscapeClick = true,
    withPortal = true,
    withCloseIcon = true,
    portalId = MAIN_PORTAL_ID,
    children
}: TProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const { hideModal }: TUseModal = useModal()

    const handleHide: TEmptyCallback = useCallback(
        (): void => (hideModal(modalId), onHide?.()),
        [hideModal, modalId, onHide]
    )

    const modalBodyRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>()

    useEffect((): TEmptyCallback => {
        if (isShown && withDisabledBodyScroll) {
            document.body.classList.add("!overflow-hidden")
        } else {
            document.body.classList.remove("!overflow-hidden")
        }

        return (): void => document.body.classList.remove("!overflow-hidden")
    }, [isShown, withDisabledBodyScroll])

    useKeyPress((): void => isShown && shouldCloseOnEscapeClick && handleHide(), ["Escape"])

    useOnClickOutside(modalBodyRef, (): void => (withOutsideClick && isShown ? handleHide() : void 0))

    useEffect((): void => isShown && modalBodyRef.current?.focus(), [isShown])

    return (
        isShown && (
            <Portal withPortal={withPortal} portalId={portalId}>
                <div className={classNames(classes.modalWrapper, isShown && classes.modalWrapperShown)}>
                    <div
                        className={classNames(classes.modalBody, isShown && classes.modalBodyShown, {
                            [modalBodyClassName]: modalBodyClassName
                        })}
                        role="dialog"
                        ref={modalBodyRef}
                        tabIndex={0}
                    >
                        {headerElement}
                        {title && (
                            <Typography
                                text={title}
                                size={ETypographySize.Heading2}
                                tag={ETypographyTag.Heading2}
                                fontWeight={ETypographyFontWeight.Bold}
                                className="text-center mb-0"
                            />
                        )}
                        {withCloseIcon && (
                            <button aria-label={t("Close button")} className={classes.closeBtn} onClick={handleHide}>
                                <CrossIconSvg />
                            </button>
                        )}
                        {children}
                    </div>
                </div>
            </Portal>
        )
    )
}

Component.displayName = "Modal"

export { Component as Modal, type TProps as TModalProps }
