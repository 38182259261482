import { journeyAchievementsQueryAdapter } from "@/3514/entities/participant-header/api/queries/journey-achievements-query/journey-achievements-query.adapter"
import { modulesExcerptsQueryAdapter } from "@/3514/entities/participant-header/api/queries/modules-excerpts-query/modules-excerpts-query.adapter"
import { nextSessionQueryAdapter } from "@/3514/entities/participant-header/api/queries/next-session-query/next-session-query.adapter"
import type {
    IJourneyAchievementDto,
    IJourneyAchievementModel,
    IModuleExcerptDto,
    IModuleExcerptModel,
    INextSessionDto,
    INextSessionModel
} from "@/3514/entities/participant-header/types"
import { sessionAttendanceByIdQueryAdapter } from "@/modals/participant-session-attendance/api/queries/session-attendance-by-id-query/session-attendance-by-id-query.adapter"
import type { ISessionAttendanceDto, ISessionAttendanceModel } from "@/modals/participant-session-attendance/types"
import type { IModuleDto, IResourceDto } from "@/pages/participant-dashboard/api/dto"
import { moduleByIdQueryAdapter } from "@/pages/participant-dashboard/api/queries/module-by-id-query/module-by-id-query.adapter"
import { resourcesQueryAdapter } from "@/pages/participant-dashboard/api/queries/resources-query/resources-query.adapter"
import type { IModuleModel, IResourceModel } from "@/pages/participant-dashboard/models"

const mockedNextSessionDto: INextSessionDto = {
    coach: { id: 1, photo: null, first_name: "John", last_name: "Doe" },
    invitation_status: "accepted",
    start: "2024-12-30T19:00:00-04:00"
}

const mockedJourneyAchievementsDtos: IJourneyAchievementDto[] = [
    { type: "session_attendance", value: 100 },
    { type: "current_streak", value: 1 },
    { type: "longest_streak", value: 3 },
    { type: "stars_earned", value: 2 }
]

const mockedModuleProgressDtos: IModuleExcerptDto[] = [
    {
        title: { en: "Empathy", es: "ESEmpathy", pt: "PTEmpathy" },
        rank: 1,
        current_module: true,
        associated_session: {
            id: 111,
            status: "tentative",
            coach: {
                id: 1,
                photo: null
            }
        },
        phases: [
            {
                order: 3,
                track_status: "on_track",
                phase_progress_bar: 1
            },
            {
                order: 2,
                track_status: "late",
                phase_progress_bar: 2
            },
            {
                order: 1,
                track_status: "not_started",
                phase_progress_bar: 3
            }
        ]
    },
    {
        title: { en: "Feedback", es: "ESFeedback", pt: "PTFeedback" },
        rank: 2,
        current_module: false,
        associated_session: {
            id: 222,
            status: "accepted",
            coach: {
                id: 1,
                photo: null
            }
        },
        phases: [
            {
                order: 3,
                track_status: "on_track",
                phase_progress_bar: 3
            },
            {
                order: 2,
                track_status: "late",
                phase_progress_bar: 2
            },
            {
                order: 1,
                track_status: "not_started",
                phase_progress_bar: 1
            }
        ]
    },
    {
        title: { en: "Delegating", es: "ESDelegating", pt: "PTDelegating" },
        rank: 3,
        current_module: false,
        associated_session: {
            id: 333,
            status: "declined",
            coach: {
                id: 1,
                photo: null
            }
        },
        phases: [
            {
                order: 3,
                track_status: "on_track",
                phase_progress_bar: 3
            },
            {
                order: 2,
                track_status: "late",
                phase_progress_bar: 2
            },
            {
                order: 1,
                track_status: "not_started",
                phase_progress_bar: 1
            }
        ]
    }
]

const mockedSessionAttendanceDtos: ISessionAttendanceDto[] = [
    // shouldn't be an array; temp solution due to lack of real api
    {
        id: 111,
        duration_minutes: 10,
        title_data: {
            en: "Coaching Session",
            es: "ESCoaching Session",
            pt: "PTCoaching Session"
        },
        status: "accepted",
        start: "2024-12-30T19:00:00-04:00",
        coach: {
            id: 1,
            photo: null,
            first_name: "CName",
            last_name: "CLastname"
        },
        participants: (() => {
            const getParticipant = (idx: number) => ({
                id: idx,
                photo: null,
                first_name: `Name${idx}`
            })

            return Array.from({ length: 10 }, (_, idx) => getParticipant(idx))
        })()
    },
    {
        id: 222,
        duration_minutes: 90,
        title_data: {
            en: "Coaching Session",
            es: "ESCoaching Session",
            pt: "PTCoaching Session"
        },
        status: "pending",
        start: "2024-12-30T19:00:00-04:00",
        coach: {
            id: 1,
            photo: null,
            first_name: "CName",
            last_name: "CLastname"
        },
        participants: (() => {
            const getParticipant = (idx: number) => ({
                id: idx,
                photo: null,
                first_name: `Name${idx}`
            })

            return Array.from({ length: 10 }, (_, idx) => getParticipant(idx))
        })()
    }
]

const mockedResourcesDtos: IResourceDto[] = [
    {
        id: 0,
        type: "article",
        content: "If You Can’t Empathize with Your Employees You’d Better Learn To",
        origin: "Harvard Business Review",
        link: "#",
        order: 3
    },
    {
        id: 1,
        type: "video",
        content: "Brené Brown on Empathy",
        origin: "RSA",
        link: "#",
        order: 2
    },
    {
        id: 2,
        type: "audio",
        content: "What’s Empathy Got to Do With It?",
        origin: "MindTools",
        link: "#",
        order: 1
    }
]

const mockedModuleDtos: IModuleDto[] = [
    {
        id: 1,
        title: { en: "Empathy" },
        rank: 1,
        phases: [
            {
                id: 11,
                order: 1,
                phase_opens_stamp: "2024-10-30T19:00:00-04:00",
                phase_due_stamp: "2024-10-30T19:00:00-04:00",
                phase_progress_bar: 2,
                track_status: "late",
                status: "in_progress",
                custom_status: "completed",
                chapters: [
                    {
                        id: 113,
                        order: 3,
                        title_data: {
                            en: "Access"
                        },
                        chapter_type: {
                            id: 1111,
                            internal_name: "Access",
                            slug: "sccess"
                        },
                        status: "queued",
                        chapter_progress: 0,
                        custom_status: "completed_in_previous_phase",
                        summary:
                            "What are you practicing this week and how will you approach it? Preparing will help you maximize your week."
                    },
                    {
                        id: 113,
                        order: 3,
                        title_data: {
                            en: "Learn"
                        },
                        chapter_type: {
                            id: 1111,
                            internal_name: "Prepare",
                            slug: "prepare"
                        },
                        status: "queued",
                        chapter_progress: 0,
                        custom_status: "completed_in_previous_phase",
                        summary:
                            "What are you practicing this week and how will you approach it? Preparing will help you maximize your week."
                    },
                    {
                        id: 113,
                        order: 3,
                        title_data: {
                            en: "Reflect"
                        },
                        chapter_type: {
                            id: 1111,
                            internal_name: "Reflect",
                            slug: "reflect"
                        },
                        status: "queued",
                        chapter_progress: 0,
                        custom_status: "completed_in_previous_phase",
                        summary:
                            "What are you practicing this week and how will you approach it? Preparing will help you maximize your week."
                    }
                ]
            }
        ]
    }
]

const mockedNextSessionModel: INextSessionModel = nextSessionQueryAdapter(mockedNextSessionDto)
const mockedJourneyAchievementsModels: IJourneyAchievementModel[] =
    journeyAchievementsQueryAdapter(mockedJourneyAchievementsDtos)
const mockedModulesExcerptsModels: IModuleExcerptModel[] = modulesExcerptsQueryAdapter(mockedModuleProgressDtos)
const mockedSessionAttendanceModels: ISessionAttendanceModel[] = mockedSessionAttendanceDtos.map(
    (m: ISessionAttendanceDto): ISessionAttendanceModel => sessionAttendanceByIdQueryAdapter(m)
)
const mockedResourcesModels: IResourceModel[] = resourcesQueryAdapter(mockedResourcesDtos)
const mockedModulesModels: IModuleModel[] = mockedModuleDtos.map(
    (m: IModuleDto): IModuleModel => moduleByIdQueryAdapter(m)
)

const mocks = {
    dtos: {
        mockedNextSessionDto,
        mockedJourneyAchievementsDtos,
        mockedModuleProgressDtos,
        mockedSessionAttendanceDtos,
        mockedResourcesDtos,
        mockedModuleDtos
    },
    models: {
        mockedNextSessionModel,
        mockedJourneyAchievementsModels,
        mockedModulesExcerptsModels,
        mockedSessionAttendanceModels,
        mockedResourcesModels,
        mockedModulesModels
    }
}

export { mocks as participantDashboardMocks }
