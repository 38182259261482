import type { FC, ReactElement, ReactNode } from "react"

import classNames from "classnames"

import { ETypographySize, ETypographyTag, Typography } from "@/3514/components"

import { EVerticalProgressBarTooltipPlacement as EPlacement } from "./vertical-progress-bar.types"

type TProps = { text: string | ReactNode; placement: EPlacement; isVisible?: boolean }

const classes: { tooltip(isVisible: boolean, placement: EPlacement): string } = {
    tooltip: (isVisible: boolean, placement: EPlacement): string =>
        classNames(
            "pointer-events-none select-none z-[10] absolute bg-white rounded-[5px] border-gray-400 transition-all duration-200",
            placement === EPlacement.Center
                ? "-translate-x-1/2 left-1/2 top-[25%]"
                : "right-full top-1/2 -translate-y-1/2 mr-[5px]",
            isVisible ? "opacity-100 border-[1px] w-max" : "opacity-0 h-0 w-0"
        )
}

const Component: FC<TProps> = ({ text, placement, isVisible = false }: TProps): ReactElement => (
    <div className={classes.tooltip(isVisible, placement)}>
        <Typography
            className="text-center block mx-[7px] my-[5px] whitespace-pre-wrap"
            text={text}
            size={ETypographySize.Small}
            tag={ETypographyTag.Span}
        />
    </div>
)

Component.displayName = "VerticalProgressBarTooltip"

export { Component as VerticalProgressBarTooltip, type TProps as TVerticalProgressBarTooltipProps }
