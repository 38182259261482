import type { FC, ReactElement } from "react"

import classNames from "classnames"

import { spinnerConfig } from "./spinner.config"
import { ESpinnerSize } from "./spinner.types"

type TProps = { size?: ESpinnerSize; className?: string }

const { sizesMap } = spinnerConfig

/**
 * Renewed spinner component
 * @param size ESpinnerSize
 * @param className string or undefined
 * @return {ReactElement}
 */
const Component: FC<TProps> = ({ size = ESpinnerSize.Medium, className = String() }: TProps): ReactElement => (
    <div className="flex items-center justify-center h-min">
        <i
            className={classNames(
                "border-[3px] border-t-transparent border-accent rounded-full animate-spin",
                className,
                {
                    [sizesMap[size]]: size
                }
            )}
        />
    </div>
)

Component.displayName = "Spinner"

export { Component as Spinner, type TProps as TSpinnerProps }
