import { CSSProperties, FC, MutableRefObject, ReactNode, useEffect, useMemo } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import useMediaQuery from "$/hooks/use-media-query"
import useDisableBodyScroll from "$/hooks/user-disable-body-scroll"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { useStoreContext } from "@/context"
import { SideMenuClose } from "@/svgs/SideMenuCloseSvg"
import SideMenuSvg from "@/svgs/SideMenuSvg"

import Button from "../button/Button"

import "./styles.scss"

enum ESidebarTag {
    Div = "div",
    Aside = "aside"
}

interface IProps {
    className?: string
    sidebarLogo?: ReactNode
    mobileLogo?: ReactNode
    closedMenu?: boolean
    children: ReactNode
    sidebarTogglerButtonRef?: MutableRefObject<HTMLButtonElement>
    tag?: ESidebarTag
}

export const SIDEBAR_MENU_TOGGLER_ID = "sidebar-menu-toggler"

const Sidebar: FC<IProps> = ({
    children,
    sidebarLogo,
    mobileLogo,
    className = "",
    closedMenu = false,
    sidebarTogglerButtonRef,
    tag: Tag = ESidebarTag.Div
}) => {
    const { isOpenBurgerMenu, isOpenSidebar, toggleSidebar } = useStoreContext()
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const { t } = useTranslation()

    useEffect(() => {
        if (isOpenSidebar && closedMenu) {
            toggleSidebar()
        }
    }, [closedMenu])

    const sideBarStyles = useMemo<CSSProperties>(() => {
        if (matches && isOpenBurgerMenu) {
            return {
                display: "flex"
            }
        }

        if (matches) {
            return {
                display: "none"
            }
        }

        return {
            display: "flex"
        }
    }, [isOpenBurgerMenu, matches])

    useDisableBodyScroll(isOpenBurgerMenu && isOpenBurgerMenu)

    return (
        <Tag
            style={sideBarStyles}
            className={classNames("width-vertical__menu", {
                [className]: className,
                closed: !matches && !isOpenSidebar
            })}
        >
            <div className="h-100">
                <div id="sidebar-menu" className="sidebar-menu">
                    {sidebarLogo ? (
                        <span aria-label="menu logo" className="sidebar-menu__logo">
                            {sidebarLogo}
                        </span>
                    ) : null}
                    {mobileLogo ? <div className="logo-left__mobile">{mobileLogo}</div> : null}
                    {!matches && isOpenSidebar ? (
                        <Button
                            ref={sidebarTogglerButtonRef}
                            variant="default"
                            className="p-0 mt-30"
                            onClick={toggleSidebar}
                            aria-label={t("Close site bar menu")}
                        >
                            <SideMenuClose />
                        </Button>
                    ) : null}
                    {isOpenSidebar || isOpenBurgerMenu ? children : null}
                    {!matches && !isOpenSidebar ? (
                        <Button
                            variant="default"
                            className="p-0 mt-30"
                            onClick={toggleSidebar}
                            aria-label={t("Open site bar menu")}
                        >
                            <SideMenuSvg />
                        </Button>
                    ) : null}
                </div>
            </div>
        </Tag>
    )
}

export { ESidebarTag }

export default Sidebar
