import { isEmpty } from "$/utils/gates"

import { getSessionAttendanceStatusEnumFromType } from "@/3514/utils"
import type { ISessionAttendanceDto, ISessionAttendanceModel } from "@/modals/participant-session-attendance/types"

function adapter(dto: ISessionAttendanceDto): ISessionAttendanceModel {
    return !isEmpty(dto)
        ? {
              sessionId: dto.id,
              attendanceStatus: getSessionAttendanceStatusEnumFromType(dto.status),
              sessionDuration: dto.duration_minutes,
              date: dto.start,
              title: dto.title_data,
              participants: isEmpty(dto.participants)
                  ? []
                  : dto.participants?.map(p => ({ firstName: p.first_name, id: p.id, photo: p.photo })),
              coach: !isEmpty(dto.coach)
                  ? {
                        id: dto.coach.id,
                        firstMame: dto.coach.first_name,
                        lastName: dto.coach.last_name,
                        photo: dto.coach.photo
                    }
                  : null
          }
        : null
}

export { adapter as sessionAttendanceByIdQueryAdapter }
