enum ETypographySize {
    Tiny = 12,
    ExtraSmall = 13,
    Small = 14,
    Medium = 16,
    Heading2 = 24,
    Heading1 = 36
}

enum ETypographyTag {
    Paragraph = "p",
    Span = "span",
    Heading1 = "h1",
    Heading3 = "h3",
    Heading2 = "h2",
    Heading5 = "h5"
}

enum ETypographyColor {
    Accent = "accent",
    Black = "black",
    Dark = "dark",
    DarkGray = "darkGray",
    LightGray = "lightGray",
    Blue = "blue",
    White = "white"
}

enum ETypographyFontWeight {
    Default = 300,
    Bold = 800
}

export { ETypographySize, ETypographyTag, ETypographyColor, ETypographyFontWeight }
