enum EBadgeVariant {
    Yellow = "yellow",
    Green = "green",
    DarkGreen = "darkgreen",
    LightGreen = "lightgreen",
    Lightblue = "blue"
}

enum EBadgeRoundVariant {
    Small = "small",
    Medium = "medium"
}

export { EBadgeVariant, EBadgeRoundVariant }
