enum ESpecialChar {
    EmptySpace = "emptySpace",
    Comma = "comma",
    Ampersand = "ampersand",
    Hyphen = "hyphen",
    Dot = "dot",
    QuestionMark = "questionMark",
    Cross = "cross",
    Colon = "colon",
    Percent = "percent",
    CheckMark = "checkMark",
    OpeningBracket = "(",
    ClosingBracket = ")"
}

export { ESpecialChar }
