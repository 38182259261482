enum EResourceType {
    Article = "article",
    Video = "video",
    Audio = "audio"
}

interface IResourceModel {
    id: number
    type: EResourceType
    content: string
    origin: string
    link: string
    order: number
}

export type { IResourceModel }
export { EResourceType }
