import type { FC, ReactElement } from "react"

type TProps = { fillColor?: string; className?: string }

const Icon: FC<TProps> = ({ fillColor = "none", className = String() }: TProps): ReactElement => (
    <svg
        {...(className && { className })}
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.86769 3.6532C9.38939 2.81411 10.6106 2.81411 11.1323 3.6532L13.0641 6.76019C13.2476 7.05542 13.5393 7.26728 13.8767 7.35062L17.4286 8.22774C18.3879 8.46462 18.7652 9.62606 18.1284 10.3815L15.7705 13.1789C15.5464 13.4447 15.435 13.7875 15.4601 14.1342L15.7235 17.7833C15.7946 18.7688 14.8066 19.4866 13.8913 19.1144L10.5022 17.7363C10.1802 17.6053 9.81978 17.6053 9.49775 17.7363L6.10866 19.1144C5.19339 19.4866 4.20541 18.7688 4.27654 17.7833L4.53994 14.1342C4.56497 13.7875 4.45359 13.4447 4.22954 13.1789L1.87156 10.3815C1.23476 9.62606 1.61214 8.46461 2.57137 8.22774L6.12325 7.35062C6.46075 7.26728 6.75235 7.05541 6.93591 6.76019L8.86769 3.6532Z"
            stroke="#737373"
            strokeWidth="1.11111"
        />
    </svg>
)

Icon.displayName = "VerticalProgressBarStarIcon"

export { Icon as VerticalProgressBarStarIcon }
