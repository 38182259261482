enum EVerticalProgressBarTooltipPlacement {
    Center = "center",
    Left = "left"
}

enum EVerticalProgressBarIndicatorVariant {
    Open = "open",
    Active = "active",
    Overdue = "overdue",
    Inactive = "inactive"
}

enum EVerticalProgressBarColorStatus {
    NotStarted = "notStarted",
    InTime = "inTime",
    Expired = "expired"
}

enum EVerticalProgressBarFillLevel {
    NotFilled = "notFilled",
    OnePieceFilled = "onePieceFilled",
    TwoPiecesFilled = "twoPieceFilled",
    ThreePiecesFilledWithStar = "threePieceFilledWithStar"
}

enum EVerticalProgressBarVariant {
    CroppedBars = "cropped",
    FullBars = "fullBars"
}

type TVerticalProgressBarPieceRenderConfig = {
    withoutStar?: boolean
    withBoldBorder?: boolean
    isHidden?: boolean
    isBottomRounded?: boolean
    isFilled?: boolean
    isAccessible?: boolean
    isBarFullHeight?: boolean
    color?: string
}

export type { TVerticalProgressBarPieceRenderConfig }

export {
    EVerticalProgressBarVariant,
    EVerticalProgressBarTooltipPlacement,
    EVerticalProgressBarFillLevel,
    EVerticalProgressBarIndicatorVariant,
    EVerticalProgressBarColorStatus
}
