import { type EnhancedStore, configureStore } from "@reduxjs/toolkit"

import { modalReducer } from "./slices"

const store: EnhancedStore = configureStore({
    reducer: {
        modal: modalReducer
    }
})

type IRootState = ReturnType<typeof store.getState>
type IAppDispatch = typeof store.dispatch

export type { IRootState, IAppDispatch }

export { store }
