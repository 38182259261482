import { useCallback, useMemo } from "react"

import { type UseQueryOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import type { AxiosError } from "axios"

import { ServerStateKeys } from "@/constants"

import type { ISurveysDto as IDto, ISurveysModel as IModel } from "./types"
import { type TSurveysApiUtils as TUtils, surveysApiUtils as utils } from "./utils"

type TParams = UseQueryOptions
type TQuery = UseQueryResult<IModel, AxiosError>
type TReturn = ReturnType<(params: TParams) => TQuery>

const { fetchSurveysList: fetcher, adaptSurveysList: adapter }: TUtils = utils

function useHook(params: TParams = { enabled: true }): TReturn {
    return useQuery<IDto, AxiosError, IModel, Array<ServerStateKeys>>(
        useMemo((): [ServerStateKeys] => [ServerStateKeys.Surveys], []),
        useCallback(fetcher, []),
        {
            // @ts-expect-error no issue here, react-query types mess
            select: useCallback(adapter, []),
            ...params
        }
    )
}

export { useHook as useSurveysQuery, type TQuery as TSurveysQuery }
