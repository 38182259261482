import { Spinner } from "@/3514/components"
import ProductTypeLogo from "@/shared/ProductTypeLogo"
import OldSpinner from "@/shared/spinner/Spinner"

type TProps = { withRenewedSpinner?: boolean }

const PageLoader = ({ withRenewedSpinner = true }: TProps) => {
    return (
        <div className="w-100 d-flex flex-column align-items-center" style={{ paddingTop: "8rem" }}>
            <ProductTypeLogo className="mb-3" width={42} heigth={42} />

            {withRenewedSpinner ? <Spinner /> : <OldSpinner />}
        </div>
    )
}

export default PageLoader
