import { isEmpty } from "$/utils/gates"

import {
    EJourneyAchievementType,
    type IJourneyAchievementDto,
    type IJourneyAchievementModel,
    type TJourneyAchievementTypeDto
} from "@/3514/entities/participant-header/types"

function _getJourneyAchievementTypeEnum(type: TJourneyAchievementTypeDto): EJourneyAchievementType {
    switch (type) {
        case "session_attendance":
            return EJourneyAchievementType.SessionAttendance
        case "current_streak":
            return EJourneyAchievementType.CurrentStreak
        case "longest_streak":
            return EJourneyAchievementType.LongestStreak
        case "stars_earned":
        default:
            return EJourneyAchievementType.StarsEarned
    }
}

function adapter(dtos: IJourneyAchievementDto[]): IJourneyAchievementModel[] {
    return !isEmpty(dtos)
        ? dtos.map(
              ({ type, value }: IJourneyAchievementDto): IJourneyAchievementModel => ({
                  type: _getJourneyAchievementTypeEnum(type),
                  value
              })
          )
        : []
}

export { adapter as journeyAchievementsQueryAdapter }
