import { type Dispatch, type SetStateAction, useMemo, useState } from "react"

import type { TEmptyCallback } from "@/shared/types/functions"

type TUseManualHover = {
    isActionAffected: boolean
    onMouseEnter: TEmptyCallback
    onMouseLeave: TEmptyCallback
    onFocus: TEmptyCallback
    onBlur: TEmptyCallback
    onMouseMove: TEmptyCallback
    onKeyDown: TEmptyCallback
}

function useManualHover(): ReturnType<() => TUseManualHover> {
    const [isActionAffected, setIsActionAffected]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false)

    return useMemo(
        (): TUseManualHover => ({
            isActionAffected,
            onMouseEnter: (): void => setIsActionAffected(true),
            onMouseLeave: (): void => setIsActionAffected(false),
            onFocus: (): void => setIsActionAffected(true),
            onBlur: (): void => setIsActionAffected(false),
            onMouseMove: (): void => setIsActionAffected(true),
            onKeyDown: (): void => setIsActionAffected((prev: boolean): boolean => !prev)
        }),
        [isActionAffected]
    )
}

export { useManualHover }
export type { TUseManualHover }
