import { ESpinnerSize } from "./spinner.types"

const sizesMap: { [K in ESpinnerSize]: string } = {
    [ESpinnerSize.Small]: "w-[20px] h-[20px]",
    [ESpinnerSize.Medium]: "w-[30px] h-[30px]",
    [ESpinnerSize.Large]: "w-[50px] h-[50px]"
}

const spinnerConfig: { sizesMap: { [K in ESpinnerSize]: string } } = { sizesMap }

export { spinnerConfig }
