export enum OnboardingSteps {
    PERSONAL_INFO_STEP = "profile",
    VIDEO_STEP = "video",
    MODULE_LIST_STEP = "module_list",
    DIAGNOSTIC_STEP = "diagnostic",
    DIAGNOSTIC_RESPONSES_STEP = "diagnostic_responses",
    REFLECTION_OBJECTIVE_STEP = "reflection_objective",
    SESSION_HOLD_TIMES = "session_hold_times",
    FINISH = "finish"
}
export enum StepUrls {
    VIDEO_STEP = "/video-step",
    MODULE_LIST_STEP = "/module-list",
    DIAGNOSTIC_STEP = "/diagnostic",
    DIAGNOSTIC_RESPONSE_STEPS = "/diagnostic-response",
    REFLECTION_OBJECTIVE_STEP = "/reflection-objective",
    SESSION_HOLD_TIMES_STEP = "/session-hold-times",
    COACH_SELECT_STEP = "/coach-select",
    ONBOARDING_REDIRECT_PAGE = "/onboarding/redirect"
}
export const onboardingProfileSetupURL = "/onboarding"

export const dashboardURL = "/dashboard"

export const COACH_BLOCK_TRANSITION_DELAY = 60

export type NavigationOnbording = {
    id: OnboardingSteps
    url: StepUrls
    title: string
    isActive: boolean
    isPassed: boolean
}

export const navigationDefault: NavigationOnbording[] = [
    {
        id: OnboardingSteps.VIDEO_STEP,
        url: StepUrls.VIDEO_STEP,
        title: "Step-1",
        isActive: true,
        isPassed: false
    },
    {
        id: OnboardingSteps.MODULE_LIST_STEP,
        url: StepUrls.MODULE_LIST_STEP,
        title: "Module list",
        isActive: false,
        isPassed: false
    },
    {
        id: OnboardingSteps.DIAGNOSTIC_STEP,
        url: StepUrls.DIAGNOSTIC_STEP,
        title: "Diagnostic",
        isActive: false,
        isPassed: false
    },
    {
        id: OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP,
        url: StepUrls.DIAGNOSTIC_RESPONSE_STEPS,
        title: "Diagnostice responses",
        isActive: false,
        isPassed: false
    },
    {
        id: OnboardingSteps.REFLECTION_OBJECTIVE_STEP,
        url: StepUrls.REFLECTION_OBJECTIVE_STEP,
        title: "Reflectioin objective",
        isActive: false,
        isPassed: false
    },
    {
        id: OnboardingSteps.SESSION_HOLD_TIMES,
        url: StepUrls.SESSION_HOLD_TIMES_STEP,
        title: "Session hold times",
        isActive: false,
        isPassed: false
    }
]

export const onBoardingNavigationKeys = [
    { id: OnboardingSteps.PERSONAL_INFO_STEP, url: onboardingProfileSetupURL },
    { id: OnboardingSteps.VIDEO_STEP, url: StepUrls.VIDEO_STEP },
    { id: OnboardingSteps.MODULE_LIST_STEP, url: StepUrls.MODULE_LIST_STEP },
    { id: OnboardingSteps.DIAGNOSTIC_STEP, url: StepUrls.DIAGNOSTIC_STEP },
    {
        id: OnboardingSteps.DIAGNOSTIC_RESPONSES_STEP,
        url: StepUrls.DIAGNOSTIC_RESPONSE_STEPS
    },
    {
        id: OnboardingSteps.REFLECTION_OBJECTIVE_STEP,
        url: StepUrls.REFLECTION_OBJECTIVE_STEP
    },
    {
        id: OnboardingSteps.SESSION_HOLD_TIMES,
        url: StepUrls.SESSION_HOLD_TIMES_STEP
    },
    {
        id: OnboardingSteps.FINISH,
        url: dashboardURL
    }
]

export enum ServerStateOnbordingKeys {
    DiagnosticResults = "diagnosticResults",
    Preprogram = "preprogram",
    ReflectionObjective = "reflectionObjective",
    PreviousSteps = "previousSteps",
    ModulesByCategory = "modulesByCategory",
    SessionHold = "sessionHold",
    AvailableCoaches = "availableCoaches",
    CoachTimeWidget = "coachTimeWidget"
}

export const onboardingUrls = [
    onboardingProfileSetupURL,
    StepUrls.VIDEO_STEP,
    StepUrls.MODULE_LIST_STEP,
    StepUrls.DIAGNOSTIC_STEP,
    StepUrls.DIAGNOSTIC_RESPONSE_STEPS,
    StepUrls.REFLECTION_OBJECTIVE_STEP,
    StepUrls.SESSION_HOLD_TIMES_STEP,
    StepUrls.COACH_SELECT_STEP,
    StepUrls.ONBOARDING_REDIRECT_PAGE
]
