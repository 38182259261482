import { useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import { Phase } from "@/models/phase"

export const getPhases = async ({ queryKey }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_key, { id }] = queryKey
    const { data } = await http.get(Urls.phases(id)) // module id
    return data.results
}

export default function usePhases(
    moduleId: number,
    options?: { refecthOnMount: boolean | "always"; enabled?: boolean }
) {
    return useQuery<Phase[]>([ServerStateKeys.Phases, { id: moduleId }], getPhases, {
        select: useCallback(data => {
            return data.map(phase => new Phase(phase)).sort((a, b) => a.openDate.localeCompare(b.openDate))
        }, []),
        // eslint-disable-next-line no-restricted-globals
        enabled: !Number.isNaN(moduleId) && !location.pathname.includes("_dashboard"), // TODO: WISER-3514 uncomment
        refetchOnMount: options?.refecthOnMount ?? true
    })
}
