import { FC, ReactNode } from "react"

import classNames from "classnames"

import type { IParticipantModel } from "@/adapters"
import { type IAuthContext, useAuthContext } from "@/context"

import "./styles.scss"

enum EParticipantWrapperSize {
    Small = "sm",
    Medium = "md",
    MaxMedium = "max-md",
    Default = "default"
}

interface IProps {
    size?: EParticipantWrapperSize
    children?: ReactNode
}

const ParticipantWrapper: FC<IProps> = ({ children, size = EParticipantWrapperSize.Small }: IProps) => {
    const { user }: IAuthContext = useAuthContext()

    return (
        <div
            className={classNames("participant-wrapper", {
                "participant-wrapper-md": size === EParticipantWrapperSize.Medium,
                "participant-wrapper-sm": size === EParticipantWrapperSize.Default,
                "participant-wrapper-max-md": size === EParticipantWrapperSize.MaxMedium,
                "fake-mode": (user as IParticipantModel)?.isFakeParticipant
            })}
        >
            {children}
        </div>
    )
}

export { EParticipantWrapperSize }

export default ParticipantWrapper
