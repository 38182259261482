import { isEmpty } from "$/utils/gates"

import type { IChapterComponentDto, IChapterDto, TChapterComponentType } from "@/pages/participant-chapter/api/dto"
import {
    EChapterComponentType,
    type IChapterComponentModel,
    type IChapterModel
} from "@/pages/participant-chapter/models"

function _getChapterComponentEnumFromType(type: TChapterComponentType): EChapterComponentType {
    switch (type) {
        case "question_group":
            return EChapterComponentType.QuestionGroup
        case "video_vimeo":
            return EChapterComponentType.VideoVimeo
        case "content":
            return EChapterComponentType.Content
        case null:
        default:
            return null
    }
}

function adapter(dto: IChapterDto): IChapterModel {
    return isEmpty(dto)
        ? null
        : {
              id: dto.id,
              phaseOrder: dto.phase_order,
              phaseName: dto.phase_name,
              moduleName: dto.module_name,
              components: isEmpty(dto.components)
                  ? []
                  : dto.components.map(
                        ({ id, content, type }: IChapterComponentDto): IChapterComponentModel => ({
                            id,
                            content,
                            type: _getChapterComponentEnumFromType(type)
                        })
                    )
          }
}

export { adapter as chapterByIdQueryAdapter }
