import { FC, ReactElement, useEffect, useMemo } from "react"

import { Navigate, Route, Routes } from "react-router-dom"

import { ScrollToTop } from "$/components/ScrollToTop"
import { useLanguage } from "$/hooks/use-language"
import { isNullOrUndefined } from "$/utils/gates"
import { setLuxonLocale } from "$/utils/lang"

import { LoadableComponent } from "@/3514/components"
import type { IParticipantModel } from "@/adapters"
import "@/assets/main-app.scss"
import { ERROR_TEXT, INDEX_URL, MAX_JS_TIMEOUT_VALUE_IN_MS, ParticipantUrls, userRoles } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import PostReflectionModalWrapper from "@/elements/reflection-modals/PostReflectionModalWrapper"
import { type TUseUserActivityParams, useUserActivity } from "@/hooks"
import { PrivacyPolicyPage } from "@/pages/privacy-policy"
import { authRoutes, coachRoutes, participantRoutes, unauthorizedPages } from "@/routes"
import ProtectedRoute from "@/routes/protected-route"
import { ELocalStorageServiceItemKey, EStorageServiceType, StorageService } from "@/services"
import { ErrorPage } from "@/shared/error-page/ErrorPage"
import { ForbiddenComponent } from "@/shared/error-page/ForbiddenComponent"
import { NotFoundPage } from "@/shared/error-page/NotFoundPage"
import TestErrorsPage from "@/shared/error-page/TestErrorsPage"
import ReloadAppModal from "@/shared/reload-app-modal/ReloadAppModal"
import PageLoader from "@/shared/spinner/PageLoader"

import Footer from "./Footer"
import Header from "./Header"
import WaitingListPage from "./waiting-list/WaitingListPage"

const localStorageService: StorageService = new StorageService(EStorageServiceType.Local)
const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

const Main: FC = (): ReactElement => {
    const { user, isLoading, logout }: IAuthContext = useAuthContext()

    const userActivityParams: TUseUserActivityParams = useMemo(
        (): TUseUserActivityParams => ({
            onInactivity: (): void => (
                console.info("%c Info: user is inactive", "background: #222; color: #bada55"), logout(), void 0
            ),
            onActivity: async (): Promise<void> => (
                localStorageService.getItem<boolean>(ELocalStorageServiceItemKey.MainUserLoggedOut) &&
                (user as IParticipantModel)?.isFakeParticipant
                    ? (sessionStorageService.clearStorage(), window.location.reload())
                    : void 0,
                void 0
            ),
            enabled: !isNullOrUndefined(user),
            ...(!isNullOrUndefined(user?.browserSessionTtl) &&
                !(user as IParticipantModel)?.isFakeParticipant && {
                    inactivityTime:
                        Number(user?.browserSessionTtl) * 1000 > MAX_JS_TIMEOUT_VALUE_IN_MS
                            ? MAX_JS_TIMEOUT_VALUE_IN_MS
                            : Number(user?.browserSessionTtl) * 1000
                }),
            ...((user as IParticipantModel)?.isFakeParticipant && { debounceTime: 1000 })
        }),
        [user, logout]
    )

    useUserActivity(userActivityParams)

    useEffect((): void => setLuxonLocale(user?.lang), [user?.lang])

    useLanguage(user)

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path={INDEX_URL} element={<ProtectedRoute user={user} isAllowed isLoading={isLoading} />} />

                {authRoutes.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <>
                                <Header />
                                <Component />
                                <Footer />
                            </>
                        }
                    />
                ))}

                {participantRoutes.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute
                                redirectPath="/forbidden"
                                isAllowed={!!user && userRoles.participant.includes(user?.role)}
                                user={user}
                                isLoading={isLoading}
                            >
                                <LoadableComponent component={<Component />} loadingFallback={<PageLoader />} />
                            </ProtectedRoute>
                        }
                    />
                ))}

                {coachRoutes.map(({ path, Component, ...rest }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute
                                redirectPath="/forbidden"
                                isAllowed={!!user && userRoles.coach.includes(user?.role)}
                                user={user}
                                isLoading={isLoading}
                            >
                                <Component {...rest} />
                            </ProtectedRoute>
                        }
                    />
                ))}

                {unauthorizedPages.map(({ path, Component }) => (
                    <Route path={path} element={<Component />} key={path} />
                ))}

                <Route path={ParticipantUrls.WAITING_LIST} element={<WaitingListPage />} />
                <Route path={ParticipantUrls.WAITING_LIST_SSO} element={<WaitingListPage />} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />

                <Route path="/error" element={<ErrorPage errorContent={ERROR_TEXT} />} />
                <Route path="/internal-error" element={<ErrorPage errorContent={ERROR_TEXT} />} />
                <Route path="/test-error-page" element={<TestErrorsPage />} />

                <Route
                    path={ParticipantUrls.END_OF_JOURNEY_PAGE}
                    element={<Navigate to={ParticipantUrls.OFFBOARDING} replace />}
                />

                <Route path="/forbidden" element={<ForbiddenComponent />} />
                <Route path="404" element={<NotFoundPage />} />

                <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <PostReflectionModalWrapper />
            <ReloadAppModal />
        </>
    )
}

export default Main
