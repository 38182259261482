// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: remove ts-nocheck
import { useCallback, useMemo } from "react"

import { type UseQueryOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import type { AxiosError } from "axios"

import { ServerStateKeys } from "@/constants"
import { IResourceDto as IDto } from "@/pages/participant-dashboard/api/dto"
import { participantDashboardMocks as mocks } from "@/pages/participant-dashboard/mock"
import { IResourceModel as IModel } from "@/pages/participant-dashboard/models"

import { resourcesQueryAdapter as adapter } from "./resources-query.adapter"
import { resourcesQueryFetcher as fetcher } from "./resources-query.fetcher"

const {
    dtos: { mockedResourcesDtos }
} = mocks

type TParams = UseQueryOptions
type TQuery = UseQueryResult<IModel[], AxiosError>
type TReturn = ReturnType<(params: TParams) => TQuery>

function useHook(params: TParams = {}): TReturn {
    return useQuery<IDto[], AxiosError, IModel[], Array<ServerStateKeys>>(
        useMemo((): [ServerStateKeys] => [ServerStateKeys.Resources3514], []),
        useCallback(fetcher, []),
        {
            // @ts-expect-error no issue here, react-query types mess
            select: useCallback(adapter, []),
            keepPreviousData: true,
            initialData: (): IDto => mockedResourcesDtos,
            ...params
        }
    )
}

export { useHook as useResourcesQuery, type TQuery as TResourcesQuery }
