import type { FC, ReactElement } from "react"

import classNames from "classnames"

import { ETypographyFontWeight, ETypographySize, Typography } from "../typography"

import { badgeConfig } from "./badge.config"
import { EBadgeRoundVariant, EBadgeVariant } from "./badge.types"

const { badgeColorsMap, badgeRoundVariant } = badgeConfig

type TProps = {
    accentText: string
    optionalText?: string
    variant: EBadgeVariant
    roundVariant?: EBadgeRoundVariant
    className?: string
    interactive?: boolean
    hoverable?: boolean
}

const Component: FC<TProps> = ({
    accentText,
    optionalText = undefined,
    variant,
    roundVariant = EBadgeRoundVariant.Small,
    className = String(),
    interactive = false,
    hoverable = false
}: TProps): ReactElement => (
    <div
        className={classNames("px-[10px] py-[7px] flex justify-center items-center select-none", className, {
            [badgeColorsMap[variant](interactive, hoverable)]: variant,
            [badgeRoundVariant[roundVariant]]: roundVariant
        })}
    >
        <Typography text={accentText} size={ETypographySize.Medium} fontWeight={ETypographyFontWeight.Bold} />
        {optionalText && <Typography text={optionalText} size={ETypographySize.Small} className="pl-[3px]" />}
    </div>
)

Component.displayName = "Badge"

export { Component as Badge, type TProps as TBadgeProps }
