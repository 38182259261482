import { FC, useEffect, useLayoutEffect, useMemo, useState } from "react"

import { useNavigate, useParams } from "react-router-dom"

import { isNullOrUndefined } from "$/utils/gates"

import useIndividualSession from "@/api/mutations/use-individual-session-assign"
import useCoach from "@/api/use-coach"
import { ConfigKeys, INDEX_URL, ParticipantUrls, SessionScheduleStatus, Status } from "@/constants"
import { type IAuthContext, useAuthContext, useStoreContext } from "@/context"
import CoachTimeScheduleModal from "@/elements/coach-time-schedule-modal/CoachTimeScheduleModal"
import JoinLiveSessionWidget from "@/elements/live-session-join/JoinLiveSessionWidget"
import MyGroups from "@/elements/my-groups/MyGroups"
import ParticipantSubHeader from "@/elements/participant-subheader"
import ParticipantWrapper, { EParticipantWrapperSize } from "@/elements/participant-wrapper"
import Phases from "@/elements/phases"
import Resources from "@/elements/resources"
import { ParticipantLayout } from "@/layouts"
import { TrackPracticeModalData } from "@/models/types"
import { SelfEnrollmentWidget } from "@/widgets/self-enrollment"

import { dashboardURL } from "../onboarding/constants"

import LeftSidebar from "./LeftSidebar"
import TrackPracticeModalContainer from "./TrackPracticeModalContainer"

import "./styles.scss"

interface IProps {}

const Dashboard: FC<IProps> = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { data: coach } = useCoach()
    const { user, refetchUser }: IAuthContext = useAuthContext()
    const { getConfig } = useStoreContext()
    const [trackPracticeModal, setShowTrackPracticeModal] = useState<TrackPracticeModalData>({
        show: false,
        component: null,
        phaseName: ""
    })
    const [showSessionScheduleModal, setShowSessionScheduleModal] = useState(false)

    const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)
    const [navigationUrl, setNavigationUrl] = useState<string>("")

    useEffect(() => {
        const showDashboard = getConfig(ConfigKeys.showDashboard)
        if (!showDashboard) {
            navigate(INDEX_URL)
        }
    }, [getConfig, navigate])

    const lastEnrolledModule = useMemo(
        // @ts-expect-error findLast seems to be not available with installed version of TS
        () => user?.enrolledModules?.findLast(module => module.status !== Status.Unassigned),
        [user?.enrolledModules]
    )

    useLayoutEffect(() => {
        let isMounted = true

        ;(() =>
            window.location.pathname === dashboardURL &&
            (user?.module
                ? setTimeout(
                      () =>
                          isMounted &&
                          (setShouldRedirect(true), setNavigationUrl(`${dashboardURL}/${user?.module?.rank}`))
                  )
                : !user?.module && lastEnrolledModule?.rank
                  ? setTimeout(
                        () =>
                            isMounted &&
                            (setShouldRedirect(true), setNavigationUrl(`${dashboardURL}/${lastEnrolledModule?.rank}`))
                    )
                  : void 0,
            user?.hasNotCompletedSurveys &&
                setTimeout(() => isMounted && (setShouldRedirect(true), setNavigationUrl(ParticipantUrls.SURVEYS)))))()

        return (): void => ((isMounted = false), void 0)
    }, [user, params, lastEnrolledModule])

    useLayoutEffect(
        (): void => (shouldRedirect ? (setShouldRedirect(false), navigate(navigationUrl)) : void 0),
        [shouldRedirect, navigate, navigationUrl]
    )

    const onTrackPracticeClick = (modalData: TrackPracticeModalData) => {
        setShowTrackPracticeModal(modalData)
    }

    const onCloseTrackPracticeModal = () => {
        setShowTrackPracticeModal({ show: false, component: null, phaseName: "" })
    }

    const onSessionScheduleClick = () => {
        setShowSessionScheduleModal(true)
    }

    const onCloseSessionScheduleModal = (bookedStatus: SessionScheduleStatus) => {
        setShowSessionScheduleModal(false)

        if (bookedStatus === SessionScheduleStatus.SCHEDULED) {
            refetchUser()
        }
    }

    const currentModule = user?.enrolledModules?.find(module => module.rank === +params?.moduleId)
    const makeScheduleSession = useIndividualSession(currentModule?.relatedSession?.session_id)

    const onSessionTimePick = notification => {
        const data = {
            session_datetime: notification.slot.start,
            coach: coach?.id,
            tz: notification.tzid
        }

        return makeScheduleSession.mutateAsync(data)
    }

    const isUnassignedModule = isNullOrUndefined(currentModule) || currentModule?.status === Status.Unassigned

    return (
        <>
            <ParticipantLayout leftSidebar={<LeftSidebar />}>
                <ParticipantWrapper size={EParticipantWrapperSize.Medium}>
                    <div className="d-flex justify-content-center">
                        <JoinLiveSessionWidget />
                    </div>
                    <ParticipantSubHeader user={user} />
                    {isUnassignedModule ? (
                        <SelfEnrollmentWidget />
                    ) : (
                        <>
                            <Phases
                                onTrackPracticeClick={onTrackPracticeClick}
                                relatedSession={currentModule?.relatedSession}
                                onSessionScheduleClick={onSessionScheduleClick}
                            />
                            <Resources />
                            <MyGroups />
                        </>
                    )}
                </ParticipantWrapper>
            </ParticipantLayout>

            <TrackPracticeModalContainer
                trackPracticeModalData={trackPracticeModal}
                onClose={onCloseTrackPracticeModal}
            />

            {showSessionScheduleModal && (
                <CoachTimeScheduleModal
                    show={showSessionScheduleModal}
                    coach={coach}
                    timePickRequest={onSessionTimePick}
                    sessionTime={currentModule?.relatedSession?.session_time}
                    session={currentModule?.relatedSession?.session_type}
                    title={currentModule?.relatedSession?.session_type?.name}
                    onClose={onCloseSessionScheduleModal}
                    showManagerInfoBlock={currentModule?.relatedSession?.is_three_way_session}
                    scheduledSession={currentModule?.relatedSession}
                    sessionId={currentModule?.relatedSession?.session_id}
                />
            )}
        </>
    )
}

export default Dashboard
