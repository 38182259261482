import { type ComponentType, type LazyExoticComponent, lazy } from "react"

import { type TParticipantHeaderProps } from "./participant-header.component"

const ParticipantHeader: LazyExoticComponent<ComponentType<TParticipantHeaderProps>> = lazy(
    (): Promise<{ default: ComponentType<TParticipantHeaderProps> }> =>
        import("./participant-header.component").then(
            ({ ParticipantHeader }): { default: ComponentType<TParticipantHeaderProps> } => ({
                default: ParticipantHeader
            })
        )
)

export { ParticipantHeader, type TParticipantHeaderProps }
