import { useMemo } from "react"

import i18next, { type i18n } from "i18next"

import type { TTFunction, TTranslationKeys } from "$/i18n/i18n"

interface IUseTranslation {
    t: TTFunction
}

function useTranslation(): ReturnType<() => IUseTranslation> {
    const { t }: i18n = i18next // react-i18next caused 2 more re-renders in every place it's called

    return useMemo((): IUseTranslation => ({ t }), [t])
}

export { useTranslation, type IUseTranslation, type TTranslationKeys, type TTFunction }
