import type { QueryFunctionContext } from "@tanstack/query-core"
import type { AxiosResponse } from "axios"

import { http } from "$/http"

import Urls from "@/api/urls"
import { ServerStateKeys } from "@/constants"
import type { IModuleDto } from "@/pages/participant-dashboard/api/dto"

async function fetcher({ queryKey }: QueryFunctionContext<[ServerStateKeys, number]>): Promise<IModuleDto> {
    const [, moduleId] = queryKey
    const { data }: AxiosResponse<IModuleDto> = await http.get(Urls.getDashboardModuleById(moduleId))

    return data
}

export { fetcher as moduleByIdQueryFetcher }
