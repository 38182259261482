import { isEmpty } from "$/utils/gates"

import type { INextSessionDto, INextSessionModel } from "@/3514/entities/participant-header/types"
import { getSessionAttendanceStatusEnumFromType } from "@/3514/utils"

function adapter(dto: INextSessionDto): INextSessionModel {
    return !isEmpty(dto)
        ? {
              coach: {
                  id: dto.coach.id,
                  photo: dto.coach.photo,
                  firstName: dto.coach.first_name,
                  lastName: dto.coach.last_name
              },
              status: getSessionAttendanceStatusEnumFromType(dto.invitation_status),
              date: dto.start
          }
        : null
}

export { adapter as nextSessionQueryAdapter }
