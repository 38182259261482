import { type FC, type ReactElement } from "react"

import classNames from "classnames"
import { type Params, useParams } from "react-router-dom"

import useMediaQuery from "$/hooks/use-media-query"
import { isEmpty } from "$/utils/gates"

import { LoadableComponent } from "@/3514/components"
import { ParticipantHeader as Header } from "@/3514/entities"
import { EParticipantHeaderVariant } from "@/3514/entities/participant-header/participant-header.ui.types"
import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type TUseModal, useModal } from "@/hooks"
import { AnalyticsModal, SessionAttendanceModal } from "@/modals"
import { type TChapterByIdQuery, useChapterByIdQuery } from "@/pages/participant-chapter/api/queries"
import { EModalId } from "@/store/slices"

import { ParticipantChapterContentContainer as ContentContainer } from "./entities"

const Container: FC = (): ReactElement => {
    const { getModal }: TUseModal = useModal()

    const { chapterId }: Readonly<Params> = useParams()

    const { data: chapterData, isFetching: isChapterDataFetching }: TChapterByIdQuery = useChapterByIdQuery({
        id: +chapterId
    })

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const isTablet: boolean = useMediaQuery(`(max-width: 1199px)`)

    return (
        <div className="flex flex-col">
            <div
                className={classNames(
                    "flex m-auto max-w-[540px] shadow-sm",
                    (isMobile || isTablet) && "overflow-hidden w-full"
                )}
            >
                <Header componentRendererProps={{ withTitle: false }} variant={EParticipantHeaderVariant.Chapter} />
            </div>

            <LoadableComponent
                component={ContentContainer}
                componentProps={{ chapter: chapterData }}
                isReady={!isEmpty(chapterData)}
                isLoading={isChapterDataFetching}
            />

            <LoadableComponent isReady={!isEmpty(getModal(EModalId.AnalyticsDashboard))} component={AnalyticsModal} />

            <LoadableComponent
                isReady={!isEmpty(getModal(EModalId.SessionAttendance))}
                component={SessionAttendanceModal}
            />
        </div>
    )
}

Container.displayName = "ParticipantChapterContainer"

export { Container as ParticipantChapterContainer }
