import type { FC, ReactElement } from "react"

type TProps = { height?: number; width?: number; fillColor?: string }

const Icon: FC<TProps> = ({ height = 23, width = 23, fillColor = "none" }: TProps): ReactElement => (
    <svg width={width} height={height} viewBox="0 0 23 23" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
        <path d="M5.8418 5.84326L17.1555 17.157" stroke="#737373" strokeWidth="1.33333" />
        <path d="M5.8418 17.1567L17.1555 5.84303" stroke="#737373" strokeWidth="1.33333" />
    </svg>
)

Icon.displayName = "CrossIconSvg"

export { Icon as CrossIconSvg }
