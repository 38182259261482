enum EJourneyAchievementType {
    SessionAttendance = "session_attendance",
    CurrentStreak = "current_streak",
    LongestStreak = "longest_streak",
    StarsEarned = "stars_earned"
}

interface IJourneyAchievementModel {
    type: EJourneyAchievementType
    value: number
}

export type { IJourneyAchievementModel }
export { EJourneyAchievementType }
