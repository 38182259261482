import { type Dispatch, type FC, type ReactElement, type ReactNode, type SetStateAction, useId, useState } from "react"

import classNames from "classnames"

import { type TUseManualHover, useManualHover } from "@/hooks"
import { VerticalProgressBarStarIcon } from "@/svgs/VerticalProgressBarStar"

import { VerticalProgressBarTooltip as Tooltip } from "./vertical-progress-bar-tooltip.component"
import { verticalProgressBarConfig as config } from "./vertical-progress-bar.config"
import {
    EVerticalProgressBarColorStatus as EColorStatus,
    EVerticalProgressBarFillLevel as EFillLevel,
    EVerticalProgressBarTooltipPlacement as ETooltipPlacement,
    EVerticalProgressBarVariant as EVariant,
    type TVerticalProgressBarPieceRenderConfig as TPieceRenderConfig
} from "./vertical-progress-bar.types"
import { verticalProgressBarUtils as utils } from "./vertical-progress-bar.utils"

const piecesClasses: {
    firstPiece(config: TPieceRenderConfig): string
    secondPiece(config: TPieceRenderConfig): string
    thirdPiece(config: TPieceRenderConfig): string
    thirdPieceBottom(config: TPieceRenderConfig): string
} = utils.generatePiecesClasses()

type TProps = {
    fillLevel: EFillLevel
    colorStatus?: EColorStatus
    variant?: EVariant
    withBottomIndicator?: boolean
    isAccented?: boolean
    isAccessible?: boolean
    wrapperClassName?: string
    renderFirstPiece?: Function
    renderSecondPiece?: Function
    renderThirdPiece?: Function
    withoutStar?: boolean
    tooltipConfig?: {
        isEnabled?: boolean
        placement?: ETooltipPlacement
        renderLabel?(fillLevel: EFillLevel, status: EColorStatus): ReactNode | string
    }
}

const Component: FC<TProps> = ({
    fillLevel: level = EFillLevel.NotFilled,
    colorStatus: status = EColorStatus.Expired,
    variant = EVariant.FullBars,
    withBottomIndicator = false,
    isAccessible = false,
    wrapperClassName = String(),
    renderFirstPiece = Function,
    renderSecondPiece = Function,
    renderThirdPiece = Function,
    withoutStar = false,
    tooltipConfig: {
        isEnabled: withTooltip = true,
        placement: tooltipPlacement = ETooltipPlacement.Center,
        renderLabel: renderTooltipLabel
    } = { isEnabled: false } as {
        isEnabled?: boolean
        placement?: ETooltipPlacement
        renderLabel?(fillLevel: EFillLevel, status: EColorStatus): string | ReactNode
    }
}: TProps): ReactElement => {
    const barId: string = useId()

    const color: string = config.barFillColorMap[status]

    const { isActionAffected, onMouseLeave, onMouseEnter }: TUseManualHover = useManualHover()

    const [tooltipValue, setTooltipValue]: [string | ReactNode, Dispatch<SetStateAction<string | ReactNode>>] =
        useState<string | ReactNode>()

    function renderTooltip(): ReactElement {
        return (
            withTooltip &&
            isAccessible && <Tooltip isVisible={isActionAffected} text={tooltipValue} placement={tooltipPlacement} />
        )
    }

    return (
        <div
            onMouseEnter={(): void => (
                onMouseEnter(), withTooltip ? setTooltipValue(renderTooltipLabel(level, status)) : void 0
            )}
            onMouseLeave={(): void => (onMouseLeave(), setTooltipValue(String()))}
            key={`vpb-${barId}`}
            className={classNames(
                "flex flex-col min-w-[35px] w-full items-center h-auto relative",
                withBottomIndicator && "pb-[5px]",
                {
                    [wrapperClassName]: wrapperClassName
                }
            )}
        >
            <div
                {...(renderFirstPiece && { style: { ...renderFirstPiece?.() } })}
                data-index={1}
                role="button"
                className={piecesClasses.firstPiece({
                    withoutStar,
                    withBoldBorder: variant === EVariant.FullBars,
                    isFilled: level === EFillLevel.ThreePiecesFilledWithStar,
                    isHidden: variant === EVariant.CroppedBars ? level === EFillLevel.NotFilled : false,
                    isAccessible,
                    color
                })}
            >
                {!withoutStar && (
                    <VerticalProgressBarStarIcon
                        className="pointer-events-none"
                        fillColor={utils.getStarFillColor(level === EFillLevel.ThreePiecesFilledWithStar)}
                    />
                )}
            </div>

            <div
                {...(renderSecondPiece && { style: { ...renderSecondPiece?.() } })}
                role="button"
                data-index={2}
                className={piecesClasses.secondPiece({
                    withBoldBorder: variant === EVariant.FullBars,
                    isFilled: level === EFillLevel.TwoPiecesFilled || level === EFillLevel.ThreePiecesFilledWithStar,
                    isHidden: variant === EVariant.CroppedBars ? level === EFillLevel.NotFilled : false,
                    isAccessible,
                    color
                })}
            />
            <div
                {...(renderThirdPiece && { style: { ...renderThirdPiece?.() } })}
                data-index={3}
                role="button"
                className={piecesClasses.thirdPiece({
                    isHidden: variant === EVariant.CroppedBars ? level === EFillLevel.NotFilled : false,
                    isFilled: level !== EFillLevel.NotFilled,
                    isBarFullHeight: variant === EVariant.CroppedBars,
                    isAccessible,
                    color
                })}
            >
                <div
                    className={piecesClasses.thirdPieceBottom({
                        isFilled: level === EFillLevel.NotFilled,
                        isHidden: level !== EFillLevel.NotFilled,
                        isBottomRounded: true,
                        isBarFullHeight:
                            variant === EVariant.CroppedBars &&
                            level === EFillLevel.NotFilled &&
                            color &&
                            status === EColorStatus.NotStarted,
                        color
                    })}
                />
            </div>

            {renderTooltip()}

            {withBottomIndicator && (
                <i
                    className={classNames("w-[5px] min-h-[5px] rounded-full relative top-[10px]", {
                        [color]: color // indicators tbd
                    })}
                />
            )}
        </div>
    )
}

Component.displayName = "VerticalProgressBar"

export { Component as VerticalProgressBar, type TProps as TProgressBarProps }
