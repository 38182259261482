enum ESessionAttendanceStatus {
    Accepted = "accepted",
    Pending = "pending",
    Declined = "rejected",
    Tentative = "tentative",
    Present = "present",
    NotPresent = "not_present"
}

export { ESessionAttendanceStatus }
