import { type LazyExoticComponent, type NamedExoticComponent, lazy } from "react"

import type { TParticipantDashboardModulePhasesProps } from "./participant-dashboard-module-phases"
import type { TParticipantDashboardModuleTitleProps } from "./participant-dashboard-module-title"

const ParticipantDashboardModuleTitle: LazyExoticComponent<
    NamedExoticComponent<TParticipantDashboardModuleTitleProps>
> = lazy(
    (): Promise<{ default: NamedExoticComponent<TParticipantDashboardModuleTitleProps> }> =>
        import("./participant-dashboard-module-title").then(
            ({
                ParticipantDashboardModuleTitle: Title
            }): { default: NamedExoticComponent<TParticipantDashboardModuleTitleProps> } => ({
                default: Title
            })
        )
)
const ParticipantDashboardModulePhases: LazyExoticComponent<
    NamedExoticComponent<TParticipantDashboardModulePhasesProps>
> = lazy(
    (): Promise<{ default: NamedExoticComponent<TParticipantDashboardModulePhasesProps> }> =>
        import("./participant-dashboard-module-phases").then(
            ({
                ParticipantDashboardModulePhases: Phases
            }): { default: NamedExoticComponent<TParticipantDashboardModulePhasesProps> } => ({
                default: Phases
            })
        )
)

export { ParticipantDashboardModuleTitle, ParticipantDashboardModulePhases }
