import type { TranslationObj } from "$/utils/lang"

enum EChapterComponentType {
    QuestionGroup = "questionGroup",
    VideoVimeo = "videoVimeo",
    Content = "content"
}

type IChapterComponentModel = {
    id: number
    type: EChapterComponentType
    content: TranslationObj
}

type IChapterModel = {
    id: number
    moduleName: TranslationObj
    phaseOrder: number
    phaseName: TranslationObj
    components: IChapterComponentModel[]
}

export type { IChapterComponentModel, IChapterModel }
export { EChapterComponentType }
